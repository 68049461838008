import * as React from "react"
import Seo from "../components/seo"
import { motion } from "framer-motion"
import "../styles/styles.sass"
import Parallax from "../components/parallax"
import Header from "../components/header"
import Footer from "../components/footer"
import HomeIntro from "../views/home/homeIntro"
import {
  Lighthouse, ChessUp, TurnTable, BoostedDS, Littera, CueHit,
  LTWebPayments, LightHub, BoostedWeb, BoostedMobile, Vega,
  PetLine, RapidReceipt, Halligan, Snupps, SnuppsMarketplace,
  Tusk, Together, SwiftDine, Wait, Chess, Classix, Blink,
  Transmute, Ikontent
} from "../views/home/homeProjects"
import Recommendations from "../views/home/homeRecommendations"
import HireBanner from "../views/hire/hireBanner"

const IndexPage = () => {
  return (
  <main className="mercuryView homeView">
    <Seo
      title="Phil Amour — Senior Product Designer"
    />
    
    <div className="headerSpacer"/>

    <Header />

    <div className="headerSpacer"/>

    <div className="mercuryContent">
      <Parallax>
        <HomeIntro
          text=""
          portrait="gray"
        />
      </Parallax>

      <article className="projectsList">
        <Blink />
        <Transmute />
        {/*<Ikontent />*/}
        <Lighthouse />

        <div className="sideBySide">
          <ChessUp />
          <TurnTable />
        </div>

        <BoostedDS />
        <Littera />
        <CueHit />
        <LTWebPayments />
        <LightHub />
        <BoostedWeb />

        <div className="sideBySide">
          <BoostedMobile />
          <Vega />
        </div>

        <PetLine />
        <RapidReceipt />

        <Halligan />
        <Snupps />

        <div className="sideBySide">
          <SnuppsMarketplace />
          <Tusk />
        </div>

        <div className="sideBySide">
          <Together />
          <SwiftDine />
        </div>

        <div className="sideBySide">
          <Wait />
          <Chess />
        </div>

        <Classix />

        <motion.div 
          initial={{ opacity: 0, translateY: 160 }}
          whileInView={{ opacity: 1, translateY: 0 }}
        >
          <Recommendations />
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, translateY: 160 }}
          whileInView={{ opacity: 1, translateY: 0 }}
        >
          <HireBanner
            bannerTitle=""
            bannerSubtitle=""
          />
        </motion.div>

      </article>

    </div>
    
    <Footer />
  </main>
  )
}

export default IndexPage
